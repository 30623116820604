import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, of} from "rxjs";
import {AuthUtils} from "./auth.utils";
import {LoginGQL, User} from "../../../generated/graphql";
import {ServiceBase} from "../service.base";
import {HttpClient} from "@angular/common/http";


@Injectable()
export class AuthService extends ServiceBase{
  public _userUservice: User | undefined;
  private _authenticated: boolean = false;
  private userDataSource = new BehaviorSubject<User>(new User());
  user = this.userDataSource.asObservable();

  constructor(private loginGQL: LoginGQL,
              public http: HttpClient)
  {
    super(http)
  }

  isAuthenticated = false;

  set accessToken(token: string) {
    localStorage.setItem('token', token);
  }


  get accessToken(): string {
    return localStorage.getItem('token') ?? '';
  }

  public getToken() {
    return localStorage.getItem('token') as string;
  }

  getStatusAuthenticated() {
    const token = localStorage.getItem('token') as string;
    if (token != null) {
      this.isAuthenticated = true;
    } else {
      this.isAuthenticated = false;
    }
    return this.isAuthenticated;
  }

  forgotPassword(_email: string) {
    // return this._httpClient.post('api/auth/forgot-password', email);
  }

  resetPassword(_password: string): Observable<any> {
    return of(null);
    // return this._httpClient.post('api/auth/reset-password', password);
  }

  /*signIn(credentials: { email: string; password: string }): Observable<LoginMutation> {
   return this.loginGQL.mutate({
      input: {
        email: credentials.email,
        password: credentials.password
      }
    }).pipe(switchMap(({data}) => {
      this._userUservice = data?.login.user as User;
      this._authenticated = true;
      this.accessToken = data?.login.token as string;
      return of(data as LoginMutation);
    }));

    // return this._httpClient.post('api/auth/sign-in', credentials).pipe(
    //     switchMap((response: any) => {
    //
    //         // Store the access token in the local storage
    //         this.accessToken = response.accessToken;
    //
    //         // Set the authenticated flag to true
    //         this._authenticated = true;
    //
    //         // Store the user on the user service
    //         this._userService.user = response.user;
    //
    //         // Return a new observable with the response
    //         return of(response);
    //     })
    // );
  }

  signInSocial(credentials: { code: string}): Observable<LoginSocialMutation> {
    return this.loginSocialGQL.mutate({
       input: {
         code: credentials.code,
       }
     }).pipe(switchMap(({data}) => {
       this._userUservice = data?.loginSocial.user as User;
       this._authenticated = true;
       this.accessToken = data?.loginSocial.token as string;
       return of(data as LoginSocialMutation);
     }));
  }*/
  signInUsingToken(): Observable<any> {
    // return this.userService.get().pipe(
    //   catchError(() =>
    //     // Return false
    //     of(false)
    //   ),
    //   switchMap((data: any) => {
    //     this._authenticated = true;
    //     // this.accessToken = response.accessToken;
    //     // this._userService.user = response.user;
    //     return of(true);
    //   })
    // )

    // return this._httpClient.post('api/auth/refresh-access-token', {
    //     accessToken: this.accessToken
    // }).pipe(
    //     catchError(() =>
    //         // Return false
    //         of(false)
    //     ),
    //     switchMap((response: any) => {
    //         // Store the access token in the local storage
    //         this.accessToken = response.accessToken;
    //         // Set the authenticated flag to true
    //         this._authenticated = true;
    //         // Store the user on the user service
    //         this._userService.user = response.user;
    //         // Return true
    //         return of(true);
    //     })
    // );

    return of(true);
  }

  signOut(): Observable<any> {
    localStorage.removeItem('token');
    this._authenticated = false;
    return of(true);
  }

  signUp(_user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return of(null);
    // return this._httpClient.post('api/auth/sign-up', user);
  }

  unlockSession(_credentials: { email: string; password: string }): Observable<any> {
    return of(null);
    // return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  check(): Observable<boolean> {

    if (this._authenticated) {
      return of(true);
    }

    if (!this.accessToken) {
      return of(false);
    }

    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }
    return this.signInUsingToken();
    // return of(true);
  }

  updateUser(user: User) {
    this.userDataSource.next(user);

  }

  upload(id: any, file: any, pathUrl = 'attachment-file', type?: string) {
    var url = `${this.BASE_URL}/upload/${pathUrl}/${id}`;
    if (type != null){
      url = url + '/' + type
    }
    return new Observable((observer) => {
      // tslint:disable-next-line:one-variable-per-declaration
      const formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      if (file != null) {
        formData.append('file', file, file.name);
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
      xhr.send(formData);
    });
  }

  uploadCustom(file: any, pathUrl : string) {
    var url = `${this.BASE_URL}/upload/${pathUrl}`;
    return new Observable((observer) => {
      // tslint:disable-next-line:one-variable-per-declaration
      const formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      if (file != null) {
        formData.append('file', file, file.name);
      }
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);
      };
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
      xhr.send(formData);
    });
  }
}
